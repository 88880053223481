import { Controller } from "stimulus"

export default class extends Controller {
  static values = { 
    title: String,
    confirmText: String,
    cancelText: String,
    refText: String,
    refRoot: String
  }
  connect() {
    this.redirect = false;
  }

  showAlert(event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    Swal.fire({
      title: "Borrar",
      showCancelButton: false,
      html: this.confirmTextValue,
      confirmButtonClass: 'add-btn',
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(this.refTextValue, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        }).then(response => {
          window.location.href = this.refRootValue
        }).catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
      }
    })
  }
}
